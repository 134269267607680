// Import styles
import 'bootstrap/scss/bootstrap.scss';
import '../scss/main.scss';

// Import components
import $ from 'jquery';  
window.jQuery = $;
window.$ = $;

// Import plugin
import 'popper.js'
import 'slick-carousel'
import 'bootstrap/js/src/index'
import 'tippy.js'
import 'tippy.js/dist/tippy.css'
import tippy from 'tippy.js';



$('.slidermain').slick({
     rtl:true,
     slidesToShow: 1,
     slidesToScroll: 1,
     autoplay: true,
     autoplaySpeed: 5000,
});
$('.slider-pro').slick({
     rtl:true,
     arrows:true,
     dots:true,
     slidesToShow: 1,
     slidesToScroll: 1,
     autoplay: true,
     autoplaySpeed: 5000,
     prevArrow:"<i class='fas fa-angle-right blogger_slider-arow-right'></i>",
     nextArrow:"<i class='fas fa-angle-left blogger_slider-arow-left'></i>",
});
             

// // slider-newse

$('.blogger_slider').slick({
    arrows: true,
    dots: true,
    rtl: true,
    // autoplay: true,
    autoplaySpeed: 2000,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    prevArrow:"<i class='fas fa-angle-right blogger_slider-arow-right'></i>",
    nextArrow:"<i class='fas fa-angle-left blogger_slider-arow-left'></i>",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  });
        

// $('.slid-prod').slick({
//      infinite: true,
//      speed: 300,
//      arrows: true,
//      rtl: true,
//      autoplay: true,
//      slidesToShow: 1,
//      adaptiveHeight: true,
//      asNavFor: '.slid-prod-nav'
//  });
//  $('.slid-prod-nav').slick({
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     asNavFor: '.slid-prod',
//     dots: true,
//     verticalSwiping:true,
//     vertical:true,
//     // centerMode: true,
 
//     focusOnSelect: true
//  });